  import { useEffect, useState } from 'react';
  import { useAuth0 } from '@auth0/auth0-react';
  import { LoginScreen } from './component/Auth/LoginScreen'
  import { AuthProvider } from './component/Auth/AuthProvider'
  import { LoginButton } from './component/Auth/LoginButton'
  
  function App() {
    const { isAuthenticated, isLoading } = useAuth0();
    const [listings, setListings] = useState([]);
    const [selectedListing, setSelectedListing] = useState(null);
    const [selectedType, setSelectedType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const getUniqueTypes = () => {
      const types = new Set(listings.map(listing => listing.type));
      return Array.from(types);
    };

   // Add error handling to your fetch
   const fetchListings = async () => {
    try {
      console.log('Fetching listings...');
      const response = await fetch('https://function-app-banter.azurewebsites.net/api/get_listings');
      console.log('Response status:', response.status);
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }
      const data = await response.json();
      console.log('Received data:', data);
      setListings(data.listings || []);
    } catch (error) {
      console.error('Detailed error:', error);
      setListings([]);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []); // E

  const AnalysisModal = ({ analysis, onClose }) => {
    const [activeTab, setActiveTab] = useState('details');
    const [displayedContent, setDisplayedContent] = useState({
      details: '',
      pricing: '',
      expert: '',
      bidding: '',
      carInfo: ''
    });
    const [isGenerating, setIsGenerating] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      requestAnimationFrame(() => {
        setIsVisible(true);
      });
    }, []);
  
    useEffect(() => {
      let index = 0;
      let allContent = {
        details: Object.entries(analysis.car_details)
          .filter(([_, value]) => value)
          .map(([key, value]) => `${key.replace('_', ' ')}: ${value}`)
          .join('\n'),
        pricing: [
          `Advertised Price: €${analysis.price_metrics.advertised_price}`,
          `Market Value: €${analysis.price_metrics.market_value}`,
          `Recommended Price: €${analysis.price_metrics.recommended_price}`,
          `Profit Percentage: ${analysis.price_metrics.profit_percentage}%`
        ].join('\n'),
        expert: [
          analysis.expert_analysis.dealer_perspective,
          analysis.expert_analysis.market_research,
          analysis.expert_analysis.risk_analysis,
          analysis.expert_analysis.consensus_summary
        ].join('\n\n'),
        bidding: [
          `Is Good Deal: ${analysis.bidding_strategy.is_good_deal ? 'Yes' : 'No'}`,
          `Deal Explanation: ${analysis.bidding_strategy.deal_explanation}`,
          `Starting Bid: €${analysis.bidding_strategy.starting_bid}`,
          `Maximum Bid: €${analysis.bidding_strategy.maximum_bid}`,
          `Timing Strategy: ${analysis.bidding_strategy.timing_strategy}`,
          'Bidding Tips:\n' + analysis.bidding_strategy.bidding_tips.join('\n')
        ].join('\n\n'),
        carInfo: [
          'Known Issues:\n' + analysis.car_type_info.known_issues.join('\n'),
          `Maintenance Costs: ${analysis.car_type_info.maintenance_costs}`,
          `Spare Parts Availability: ${analysis.car_type_info.spare_parts_availability}`,
          `Resale Value Trend: ${analysis.car_type_info.resale_value_trend}`,
          'Model Specific Checks:\n' + analysis.car_type_info.model_specific_checks.join('\n')
        ].join('\n\n')
      };
  
      let totalLength = Object.values(allContent).join('').length;
      const interval = setInterval(() => {
        if (index < totalLength) {
          setDisplayedContent(prev => ({
            details: allContent.details.slice(0, index),
            pricing: allContent.pricing.slice(0, Math.max(0, index - allContent.details.length)),
            expert: allContent.expert.slice(0, Math.max(0, index - allContent.details.length - allContent.pricing.length)),
            bidding: allContent.bidding.slice(0, Math.max(0, index - allContent.details.length - allContent.pricing.length - allContent.expert.length)),
            carInfo: allContent.carInfo.slice(0, Math.max(0, index - allContent.details.length - allContent.pricing.length - allContent.expert.length - allContent.bidding.length))
          }));
          index++;
        } else {
          setIsGenerating(false);
          clearInterval(interval);
        }
      }, 10);
  
      return () => clearInterval(interval);
    }, [analysis]);
  
    const handleClose = () => {
      setIsVisible(false);
      setTimeout(onClose, 300);
    };
  
    return (
      <div className={`fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-2 z-50 transition-opacity duration-300 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`} onClick={handleClose}>
        <div className={`bg-white rounded-xl w-full max-h-[95vh] overflow-hidden shadow-2xl transition-all duration-300 ${
          isVisible ? 'scale-100 translate-y-0' : 'scale-95 translate-y-4'
        }`} onClick={e => e.stopPropagation()}>
          <div className="bg-gradient-to-l from-[#7FFFD4] to-[#1a1a1a] p-4 text-white">
            <div className="flex justify-between items-center">
              <div className="flex flex-col items-center flex-grow">
                <h3 className="text-xl font-bold">AI the *** out of this deal!</h3>
                <p className="text-xs opacity-75">AI can make mistakes - keep an open mind :)</p>
              </div>
              <button 
                onClick={handleClose}
                className="rounded-full p-2 hover:bg-white/10 transition-colors"
              >
                <span className="sr-only">Close</span>
                ✕
              </button>
            </div>
          </div>
  
          <div className="border-b border-gray-200 overflow-x-auto">
            <div className="flex justify-center p-2 min-w-max">
              {['details', 'pricing', 'expert', 'bidding', 'carInfo'].map((tab) => (
                <button
                  key={tab}
                  className={`px-3 py-1.5 rounded-lg text-sm transition-colors ${
                    activeTab === tab 
                      ? 'bg-[#7FFFD4]/20 text-[#2F4F4F] font-medium' 
                      : 'hover:bg-gray-100 text-gray-600'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab === 'carInfo' ? 'Car Info' : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div>
  
          <div className="p-4 overflow-y-auto h-[75vh]">
            {activeTab === 'details' && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">    
                {Object.entries(analysis.car_details)
                  .filter(([_, value]) => value)
                  .map(([key]) => (
                    <div key={key} className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                      <p className="text-sm font-medium capitalize text-gray-600">{key.replace('_', ' ')}</p>
                      <p className="text-base text-gray-900">
                        {analysis.car_details[key]}
                        {isGenerating && <span className="animate-pulse ml-1">▊</span>}
                      </p>
                    </div>
                  ))}
              </div>
            )}
  
            {activeTab === 'pricing' && (
              <div className="grid grid-cols-1 gap-3">
                {[
                  ['Advertised Price', analysis.price_metrics.advertised_price],
                  ['Market Value', analysis.price_metrics.market_value],
                  ['Recommended Price', analysis.price_metrics.recommended_price],
                  ['Profit Percentage', `${analysis.price_metrics.profit_percentage}%`]
                ].map(([label, value]) => (
                  <div key={label} className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                    <p className="text-sm font-medium text-gray-600">{label}</p>
                    <p className="text-base text-gray-900">
                      {label.includes('Price') ? `€${value}` : value}
                      {isGenerating && <span className="animate-pulse ml-1">▊</span>}
                    </p>
                  </div>
                ))}
              </div>
            )}
  
            {activeTab === 'expert' && (
              <div className="space-y-3">
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Dealer Perspective</h4>
                  <p className="text-sm text-gray-700 whitespace-pre-line">
                    {analysis.expert_analysis.dealer_perspective}
                  </p>
                </div>
  
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Market Research</h4>
                  <p className="text-sm text-gray-700 whitespace-pre-line">
                    {analysis.expert_analysis.market_research}
                  </p>
                </div>
  
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Risk Analysis</h4>
                  <p className="text-sm text-gray-700 whitespace-pre-line">
                    {analysis.expert_analysis.risk_analysis}
                  </p>
                </div>
  
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Consensus Summary</h4>
                  <p className="text-sm text-gray-700 whitespace-pre-line">
                    {analysis.expert_analysis.consensus_summary}
                  </p>
                </div>
              </div>
            )}
  
            {activeTab === 'bidding' && (
              <div className="space-y-3">
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Deal Assessment</h4>
                  <p className={`text-base font-bold ${analysis.bidding_strategy.is_good_deal ? 'text-green-600' : 'text-red-600'}`}>
                    {analysis.bidding_strategy.is_good_deal ? 'Good Deal ✓' : 'Not Recommended ✗'}
                  </p>
                  <p className="text-sm text-gray-700 mt-2">{analysis.bidding_strategy.deal_explanation}</p>
                </div>
                
                <div className="grid grid-cols-1 gap-3">
                  <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-900 mb-1">Recommended Bids</h4>
                    <p className="text-sm">Starting: €{analysis.bidding_strategy.starting_bid}</p>
                    <p className="text-sm">Maximum: €{analysis.bidding_strategy.maximum_bid}</p>
                  </div>
                  <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-900 mb-1">Timing Strategy</h4>
                    <p className="text-sm">{analysis.bidding_strategy.timing_strategy}</p>
                  </div>
                </div>
                
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Bidding Tips</h4>
                  <ul className="list-disc pl-4 space-y-1">
                    {analysis.bidding_strategy.bidding_tips.map((tip, index) => (
                      <li key={index} className="text-sm">{tip}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
  
            {activeTab === 'carInfo' && (
              <div className="space-y-3">
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Known Issues</h4>
                  <ul className="list-disc pl-4 space-y-1">
                    {analysis.car_type_info.known_issues.map((issue, index) => (
                      <li key={index} className="text-sm">{issue}</li>
                    ))}
                  </ul>
                </div>
                
                <div className="grid grid-cols-1 gap-3">
                  <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-900 mb-1">Maintenance</h4>
                    <p className="text-sm">{analysis.car_type_info.maintenance_costs}</p>
                  </div>
                  <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-900 mb-1">Spare Parts</h4>
                    <p className="text-sm">{analysis.car_type_info.spare_parts_availability}</p>
                  </div>
                </div>
                
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Resale Value Trend</h4>
                  <p className="text-sm">{analysis.car_type_info.resale_value_trend}</p>
                </div>
                
                <div className="bg-[#7FFFD4]/20 p-3 rounded-lg">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">Pre-Purchase Checks</h4>
                  <ul className="list-disc pl-4 space-y-1">
                    {analysis.car_type_info.model_specific_checks.map((check, index) => (
                      <li key={index} className="text-sm">{check}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-gray-800 text-xl">Loading...</div>
      </div>
    );
  }

    if (!isAuthenticated) {
      return <LoginScreen />;
    }

    return (
      <AuthProvider>
        <div className="min-h-screen p-2 sm:p-4 bg-white">
          <div className="max-w-[1400px] mx-auto px-2 sm:px-4">
            {/* Header with title and login */}
            <div className="flex justify-between items-center mb-4">
              <div>
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-1">BargainBuster.ai</h1>
                <span className="text-sm text-gray-500 block -mt-1">Gets car lovers up to speed!</span>
              </div>
              <LoginButton />
            </div>
  <div className="relative mb-4">
  <div className="relative">
    <input
      type="text"
      placeholder="Search listings..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="w-full px-12 py-3 bg-white rounded-full shadow-md 
                 border border-gray-200 
                 focus:outline-none focus:ring-2 focus:ring-[#7FFFD4]/50"
    />
    <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
      <svg 
        className="w-5 h-5 text-gray-400" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
        stroke="currentColor"
      >
        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
      </svg>
    </div>
  </div>
</div>

  {/* Banner section with floating filter */}
  <div className="relative mb-8">
    <img 
      src="/image.png"
      alt="Banner" 
      className="w-full h-[200px] object-cover rounded-lg shadow-lg"
    />
    
    {/* Floating filter */}
    <div className="absolute top-4 left-4 bg-white/90 p-3 rounded-lg shadow-md backdrop-blur-sm">
      <select
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
        className="bg-white/50 border border-gray-300 rounded-md px-3 py-2 text-sm min-w-[150px]"
      >
        <option value="">All Types</option>
        {getUniqueTypes().map((type) => (
          <option key={type} value={type}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </option>
        ))}
      </select>
    </div>
  </div>

  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 sm:gap-4">
  {listings
  .filter(listing => {
    const matchesType = !selectedType || listing.type === selectedType;
    const matchesSearch = !searchQuery || 
      listing.title.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesType && matchesSearch;
  })
  .map((listing, index) => (
        <div 
          key={listing.id} 
          className="opacity-0 bg-[#7FFFD4]/30 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 flex flex-col h-full animate-card-appear"
          style={{
            animationDelay: `${index * 100}ms`,
            animationFillMode: 'forwards'
          }}
        >
          {listing.image_urls && listing.image_urls.length > 0 && (
            <div className="relative h-40">
              <img
                src={listing.image_urls[0]}
                alt={listing.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-2 left-2">
                <span className="bg-white/90 text-gray-700 text-xs px-2 py-1 rounded shadow-sm">
                  {listing.source || 'marktplaats.nl'}
                </span>
              </div>
            </div>
          )}

<div className="p-2 sm:p-4 flex-grow flex flex-col">
                    {/* Title */}
                    <h2 className="text-xs font-semibold text-gray-800 mb-2">{listing.title}</h2>

                    {/* Price */}
                    {listing.fixed_price ? (
                      <div className="text-xs font-bold text-gray-900 mb-2">
                        Fixed price €{listing.fixed_price},-
                      </div>
                    ) : (
                      <div className="text-xs  font-bold text-gray-900 mb-2">
                        Minimum bid: €{listing.minimum_bid},-
                      </div>)}
                          
                    {/* Market Value */}
                    {listing.analysis?.market_value && (
                      <div className="text-xs text-gray-700 mb-2">
                        Market value: €{listing.analysis.market_value}
                      </div>
                    )}
                    
                    {/* Date */}
                    <div className="text-sm text-gray-600 mb-2">
                      {new Date(listing.timestamp).toLocaleDateString('nl-NL', {
                        month: 'numeric',
                        year: 'numeric',
                      })} 
                    </div>

                    {/* Profit percentage */}
                    {listing.analysis?.profit_percentage && (
                      <div className="text-sm font-medium mb-2">
                        <span
                          className={`${
                            parseFloat(listing.analysis.profit_percentage) > 30
                              ? 'text-green-600'
                              : parseFloat(listing.analysis.profit_percentage) > 20
                              ? 'text-lime-600'
                              : parseFloat(listing.analysis.profit_percentage) > 10
                              ? 'text-yellow-600'
                              : 'text-red-600'
                          }`}
                        >
                          Profit: {listing.analysis.profit_percentage}%
                        </span>
                      </div>
                    )}
  {/* Actions - Bottom buttons */}
  <div className="mt-auto flex justify-between items-center gap-1 sm:gap-2">
  <a
    href={listing.url}
    target="_blank"
    rel="noopener noreferrer"
    className="flex-1 bg-[#1a1a1a] text-white text-xs sm:text-sm px-2 sm:px-4 py-2 rounded 
              relative group overflow-hidden
              transition-all duration-300
              hover:-translate-y-1 hover:scale-105
              hover:bg-[#2a2a2a]
              hover:shadow-lg hover:shadow-[#7FFFD4]/20
              flex justify-center items-center"
  >
    <span className="relative z-10">Go to add</span>
    <div className="absolute inset-0 
                    bg-gradient-to-r from-transparent via-[#7FFFD4]/10 to-transparent
                    translate-x-[-100%] group-hover:animate-shine" />
  </a>
  <button
    onClick={() => setSelectedListing(listing)}
    className="flex-1 bg-[#1a1a1a] text-white text-xs sm:text-sm px-2 sm:px-4 py-2 rounded
              relative group overflow-hidden
              transition-all duration-500
              hover:-translate-y-2 hover:scale-110
              hover:shadow-xl hover:shadow-[#7FFFD4]/20
              border border-[#7FFFD4]/10
              flex justify-center items-center"
  >
    <span className="relative z-10 font-semibold tracking-wide">AI-ify!</span>
    <div className="absolute inset-0 
                    bg-gradient-to-r from-transparent via-[#7FFFD4]/10 to-transparent
                    translate-x-[-100%] animate-shine-rainbow" />
    <div className="absolute inset-0 
                    bg-[linear-gradient(60deg,#1a1a1a_30%,rgba(255,99,97,0.2)_45%,rgba(62,243,186,0.2)_50%,rgba(99,168,255,0.2)_55%,#1a1a1a_70%)]
                    opacity-0 group-hover:opacity-100 
                    transition-opacity duration-500" />
  </button>
</div>
                  </div>
                </div>
              ))}
            </div>

            {listings.length === 0 && (
              <div className="text-center py-8">
                <p className="text-[#E8F0F0]">No listings found</p>
              </div>
            )}

{selectedListing && selectedListing.analysis && (
  <AnalysisModal 
    analysis={{
      car_details: selectedListing.analysis.car_details || {},
      expert_analysis: {
        dealer_perspective: selectedListing.analysis.dealer_perspective || 'No dealer perspective available',
        market_research: selectedListing.analysis.market_research || 'No market research available',
        risk_analysis: selectedListing.analysis.risk_analysis || 'No risk analysis available',
        consensus_summary: selectedListing.analysis.consensus_summary || 'No consensus summary available'
      },
      price_metrics: {  // Changed from price_analysis to match your model
        advertised_price: selectedListing.analysis.advertised_price || 0,
        market_value: selectedListing.analysis.market_value || 0,
        recommended_price: selectedListing.analysis.recommended_price || 0,
        profit_percentage: selectedListing.analysis.profit_percentage || 0
      },
      bidding_strategy: {
        is_good_deal: selectedListing.analysis.bidding_strategy?.is_good_deal || false,
        deal_explanation: selectedListing.analysis.bidding_strategy?.deal_explanation || '',
        starting_bid: selectedListing.analysis.bidding_strategy?.starting_bid || 0,
        maximum_bid: selectedListing.analysis.bidding_strategy?.maximum_bid || 0,
        bidding_tips: selectedListing.analysis.bidding_strategy?.bidding_tips || [],
        timing_strategy: selectedListing.analysis.bidding_strategy?.timing_strategy || ''
      },
      car_type_info: {
        known_issues: selectedListing.analysis.car_type_info?.known_issues || [],
        maintenance_costs: selectedListing.analysis.car_type_info?.maintenance_costs || '',
        spare_parts_availability: selectedListing.analysis.car_type_info?.spare_parts_availability || '',
        resale_value_trend: selectedListing.analysis.car_type_info?.resale_value_trend || '',
        model_specific_checks: selectedListing.analysis.car_type_info?.model_specific_checks || []
      }
    }}
    onClose={() => setSelectedListing(null)}
  />
)}
          </div>
        </div>
      </AuthProvider>
    );
  }

  export default App;