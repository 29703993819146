import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect } from 'react';

const ALLOWED_EMAILS = ["koensegers.clouddev@gmail.com"]; // Empty = everyone can access, add emails to restrict

export function LoginButton() {
 const { logout, isAuthenticated, user } = useAuth0();

 useEffect(() => {
   if (isAuthenticated && user) {
     // Check if user is allowed
     if (ALLOWED_EMAILS.length > 0 && !ALLOWED_EMAILS.includes(user.email)) {
       logout({ returnTo: window.location.origin });
       return;
     }

     // Try to register or login the user
     axios.post('http://localhost:5000/api/auth/register', {
       email: user.email,
       name: user.name,
       picture: user.picture,
       authProvider: user.sub.split('|')[0],
       providerId: user.sub.split('|')[1]
     })
     .then(response => {
       console.log('Auth successful:', response.data);
     })
     .catch(error => {
       if (error.response?.data?.error?.includes('duplicate key')) {
         // User already exists, this is fine
         console.log('User already registered');
       } else {
         // Some other error occurred
         console.error('Auth error:', error);
       }
     });
   }
 }, [isAuthenticated, user, logout]);

 if (isAuthenticated) {
   return (
     <div className="flex flex-wrap items-center gap-2 sm:gap-4">
       <div className="flex items-center gap-2 sm:gap-3">
         <img 
           src={user.picture} 
           alt={user.name} 
           className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 border-white shadow-lg"
         />
         <span className="hidden sm:inline text-white font-medium">{user.name}</span>
       </div>
       <button 
         onClick={() => logout()} 
         className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 text-sm rounded-md 
         transition-colors duration-200 shadow-md hover:shadow-lg whitespace-nowrap"
       >
         Log Out
       </button>
     </div>
   );
 }

 return null;
}