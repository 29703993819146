// LoginScreen.jsx
import { useAuth0 } from '@auth0/auth0-react';

export function LoginScreen() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="min-h-screen flex items-center justify-center bg-white bg-[url('/path-to-car-pattern.png')] bg-repeat">
      <div className="max-w-md w-full mx-4">
        <div className="bg-[#7FFFD4]/30 rounded-lg shadow-lg overflow-hidden backdrop-blur-sm">
          <div className="px-8 py-10">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-800 mb-1">
                BargainBuster.ai
              </h2>
              <span className="text-sm text-gray-500 block">
                Gets car lovers up to speed!
              </span>
            </div>
            
            <div className="space-y-6">
              <button
                onClick={() => loginWithRedirect()}
                className="w-full bg-[#1a1a1a] hover:bg-[#2a2a2a] text-white py-3 px-4 rounded
                         transition-colors duration-200
                         flex items-center justify-center gap-3 font-medium"
              >
                Start Your Journey
              </button>
            </div>
          </div>
          
          <div className="px-6 py-4 bg-white/50 border-t border-[#7FFFD4]/50">
            <p className="text-xs text-gray-600 text-center">
              Your gateway to smarter car investments
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}